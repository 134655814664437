import { Select } from '@maersktankersdigital/web-components'
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material'
import { LoadPort } from './autocomplete/load-port'
import { DateInputField } from './date-input-field'
import { FieldsGroupProps, rowStyle } from './shared-fields'

export function SpotFields(props: FieldsGroupProps) {
  const { updateFormInput, formInput } = props
  return (
    <Box sx={{ ...rowStyle, mt: 5 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="label">Cargo Grade*</Typography>
        <TextField
          sx={{ mt: 2 }}
          value={formInput['cargo-grade'] || ''}
          onChange={(e) =>
            updateFormInput({ key: 'cargo-grade', value: e.target.value })
          }
        />
      </Box>

      <Box sx={{ display: 'flex', gap: 2, alignItems: 'end' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="label">Quantity*</Typography>
          <TextField
            sx={{ mt: 2 }}
            value={formInput['quantity'] || ''}
            onChange={(e) =>
              updateFormInput({ key: 'quantity', value: e.target.value })
            }
          />
        </Box>
        <Select name="unit" options={[]} />
      </Box>

      <LoadPort {...props} />

      {/* <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <AutocompleteCargoList label="load world area *" options={[]} />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <AutocompleteCargoList label="discharge port" options={[]} />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <AutocompleteCargoList label="discharge world area *" options={[]} />
      </Box> */}

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="label">broker *</Typography>
        <TextField
          sx={{ mt: 2 }}
          value={formInput['broker'] || ''}
          onChange={(e) =>
            updateFormInput({ key: 'broker', value: e.target.value })
          }
        />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="label">charterer *</Typography>
        <TextField
          sx={{ mt: 2 }}
          value={formInput['charterer'] || ''}
          onChange={(e) =>
            updateFormInput({ key: 'charterer', value: e.target.value })
          }
        />
        <FormControlLabel
          label="To be named (TBN)"
          control={
            <Checkbox
              checked={formInput['charterer-tbn'] === 'true'}
              onChange={(e, isChecked) =>
                updateFormInput({
                  key: 'charterer-tbn',
                  value: isChecked.toString(),
                })
              }
            />
          }
        />
      </Box>

      <Divider sx={{ width: '100%' }} />

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="label">vessel</Typography>
        <TextField
          sx={{ mt: 2 }}
          value={formInput['vessel'] || ''}
          onChange={(e) =>
            updateFormInput({ key: 'vessel', value: e.target.value })
          }
        />
        <FormControlLabel
          label="To be named (TBN)"
          control={
            <Checkbox
              checked={formInput['vessel-tbn'] === 'true'}
              onChange={(e, isChecked) =>
                updateFormInput({
                  key: 'vessel-tbn',
                  value: isChecked.toString(),
                })
              }
            />
          }
        />
      </Box>

      <Box sx={{ display: 'flex', gap: 2, alignItems: 'end' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="label">rate</Typography>
          <TextField
            sx={{ mt: 2 }}
            value={formInput['rate'] || ''}
            onChange={(e) =>
              updateFormInput({ key: 'rate', value: e.target.value })
            }
          />
        </Box>
        <Select name="rate" options={[]} />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="label">c/p date</Typography>
        <DateInputField
          onChangeHandler={(value) =>
            updateFormInput({ key: 'cp-date', value })
          }
          value={formInput['cp-date']}
        />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="label">comments</Typography>
        <TextField
          placeholder="..."
          sx={{ mt: 2, minHeight: '104px' }}
          value={formInput['comments'] || ''}
          onChange={(e) =>
            updateFormInput({ key: 'comments', value: e.target.value })
          }
        />
      </Box>
    </Box>
  )
}
