import { TextField } from '@mui/material'
import { useState } from 'react'
import { NumberFormatValues, PatternFormat } from 'react-number-format'

function validateDate(date: string) {
  const regex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{2}$/
  return regex.test(date)
}

export function DateInputField({
  onChangeHandler,
  value,
}: {
  onChangeHandler: (value: string) => void
  value: string
}) {
  const [isValid, setIsValid] = useState<boolean>(true)

  function onValueChangeHandler(values: NumberFormatValues) {
    const isValid = validateDate(values.formattedValue)
    if (isValid) {
      onChangeHandler(values.formattedValue)
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }

  return (
    <PatternFormat
      format="##-##-##"
      customInput={TextField}
      sx={{ mt: 2 }}
      placeholder="dd-mm-yy"
      onValueChange={onValueChangeHandler}
      value={value || ''}
      helperText={!isValid ? 'Invalid format use dd-mm-yy' : ''}
    />
  )
}
