import { Alert, Checkbox, Switch } from '@maersktankersdigital/web-components'
import { Typography } from '@mui/material'
import { keepPreviousData } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { CategoricalChartState } from 'recharts/types/chart/types'
import { getMTOpenVessels } from '~api/gql/queries'
import LoadComponent from '~components/molecules/load-component/load-component'
import { Tooltip } from '~components/tooltip'
import { useGraphQL } from '~hooks/use-graphql'
import {
  ExposureFilterProvider,
  ExposureFilterTypes,
  OpenVessels,
  useExposureFilters,
} from '~pages/pages-behind-login/position-list/contexts/exposure-filter-provider'
import ExposureFilterBar from '~pages/pages-behind-login/position-list/exposure-page/exposure-filter-bar/exposure-filter-bar'
import ExposureList from '~pages/pages-behind-login/position-list/exposure-page/exposure-list/exposure-list'
import ExposureTable from '~pages/pages-behind-login/position-list/exposure-page/exposure-table/exposure-table'
import {
  FormControls,
  StyledTitle,
  StyledWrapper,
  TitleWrapper,
  TopWrapper,
} from '~pages/pages-behind-login/position-list/exposure-page/exposure-table/exposure-table.styles'
import NavButton from '~pages/pages-behind-login/position-list/position-list-page/nav-button'
import PositionListHeader from '~pages/pages-behind-login/position-list/position-list-page/position-list-header'
import {
  Divider,
  NavButtonDivider,
  NavButtonsWrapper,
} from '~pages/pages-behind-login/position-list/position-list.styles'
import { ModalWrapper } from '~pages/pages-behind-login/user-page/user-page.styles'
import { formatDate } from '~utils/dates'
import { cargoListEnabled, getFeatureToggle } from '~utils/feature-toggles'
import { gtm } from '~utils/gtm'
import { usePageViewTracking } from '../tracking/hooks/use-page-view-tracking'

export default function ExposurePage() {
  const {
    data: openVesselsData,
    isLoading,
    isError,
  } = useGraphQL(getMTOpenVessels, {
    staleTime: 1000 * 60 * 60 * 1, // 1 hour
    placeholderData: keepPreviousData,
  })

  if (isLoading) {
    return <LoadComponent />
  }

  if (isError) {
    return (
      <ModalWrapper>
        <Alert
          variant="error"
          text="Oops there was an error. Please reload the page or try again later."
        />
      </ModalWrapper>
    )
  }

  return (
    <ExposureFilterProvider data={openVesselsData?.getMTOpenVessels}>
      {openVesselsData && (
        <Exposure
          latestCreatedDate={openVesselsData.getMTOpenVessels.latestCreatedDate}
        />
      )}
    </ExposureFilterProvider>
  )
}

interface Props {
  latestCreatedDate: string
}

function Exposure({ latestCreatedDate }: Props) {
  const { filters, handleChangeFilters } = useExposureFilters()
  const { cargoGroup, pool } = filters || {}
  const [activeSelection, setActiveSelection] = useState<OpenVessels>()
  usePageViewTracking()

  const lastUpdateDateFormatted = formatDate(
    latestCreatedDate || '',
    'dd/MM/yyyy - HH:mm',
  )

  useEffect(() => {
    setActiveSelection(undefined)
  }, [filters?.totalExposure])

  function handleChartClick(data: CategoricalChartState) {
    if (!data.activePayload) {
      return
    }

    gtm.pushEvent('internal_click', {
      click_type: 'Exposure in selected week',
      click_subtype: data.activePayload?.[0].payload.exposureArea,
    })

    const vessels = data.activePayload?.[0].payload
    setActiveSelection(vessels)
  }

  const handleFilterChange = (
    event:
      | React.KeyboardEvent<Element>
      | React.MouseEvent<Element, MouseEvent>
      | React.FocusEvent<Element, Element>
      | null,
    newValue: string[] | null,
    name?: string,
  ) => {
    if (!name || !newValue) return
    setActiveSelection(undefined)

    gtm.pushEvent('internal_click', {
      click_type: 'filter_exposure',
      click_subtype: name,
    })
    handleChangeFilters(name as keyof ExposureFilterTypes, newValue)
  }

  return (
    <>
      <PositionListHeader />
      <StyledWrapper>
        <ExposureFilterBar onFilterChange={handleFilterChange} />
        <Divider />
        <TabNavigation
          extendedPlUrl={`/position-list?cargoGroup=${cargoGroup}&pool=${pool}`}
        />
        <TopWrapper>
          <TitleWrapper>
            <StyledTitle>Open Vessels</StyledTitle>
            <Tooltip
              size={20}
              title={
                <Typography
                  sx={{
                    maxWidth: '400px',
                  }}
                >
                  Vessel exposure for the current week and the next three weeks,
                  sorted by week and region. The current week includes vessels
                  that opened in previous weeks and are still open. Data is
                  updated bi-hourly; the last update was on:{' '}
                  {lastUpdateDateFormatted}.
                </Typography>
              }
              placement="right"
            />
          </TitleWrapper>
          <FormControls>
            <Switch
              name="totalExposure"
              label="Show Total Exposure"
              checked={Boolean(filters?.totalExposure)}
              onChange={(e) => {
                handleChangeFilters('weeks', [])
                handleChangeFilters('totalExposure', e.target.checked)
              }}
            />
            <Checkbox
              label="Show in Percentage"
              name="showPercentage"
              checked={Boolean(filters?.showPercentage)}
              onChange={(e) =>
                handleChangeFilters('showPercentage', e.target.checked)
              }
              data-cy="percentage"
            />
          </FormControls>
        </TopWrapper>
        <ExposureTable onChartClick={handleChartClick} />
        <ExposureList activeSelection={activeSelection} />
      </StyledWrapper>
    </>
  )
}

export function TabNavigation({
  extendedPlUrl,
  extendedExposureUrl,
}: {
  extendedPlUrl?: string
  extendedExposureUrl?: string
}) {
  return (
    <NavButtonsWrapper>
      <NavButton end to={extendedPlUrl ? extendedPlUrl : '/position-list'}>
        List
      </NavButton>
      <NavButtonDivider />
      <NavButton
        end
        to={
          extendedExposureUrl ? extendedExposureUrl : '/position-list/exposure'
        }
      >
        Exposure
      </NavButton>
      {getFeatureToggle(cargoListEnabled) && (
        <>
          <NavButtonDivider />
          <NavButton end to="/position-list/cargo-list">
            Cargo List
          </NavButton>
        </>
      )}
    </NavButtonsWrapper>
  )
}
