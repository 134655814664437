import { Popup } from '@mui/base/Unstable_Popup/Popup'
import { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useApi } from 'src/hooks/use-api'
import { ApiRoutes } from '~api/routes'
import LoadComponent from '~components/molecules/load-component/load-component'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { FilteredDataProvider } from '~pages/pages-behind-login/position-list/contexts/filtered-data-provider'
import {
  PositionListViewProvider,
  usePositionListView,
} from '~pages/pages-behind-login/position-list/contexts/position-list-view-provider'
import { useMyLists } from '~pages/pages-behind-login/position-list/position-list'
import ExportButtons from '~pages/pages-behind-login/position-list/position-list-page/export-buttons/export-buttons'
import DeleteListModal from '~pages/pages-behind-login/position-list/position-list-page/modals/delete-list-modal/delete-list-modal'
import ExportModal from '~pages/pages-behind-login/position-list/position-list-page/modals/export-modal/export-modal'
import NewListModal from '~pages/pages-behind-login/position-list/position-list-page/modals/new-list-modal/new-list-modal'
import MyListsMenuContent from '~pages/pages-behind-login/position-list/position-list-page/my-list-menu-content/my-list-menu-content'
import { PositionListFilterBar } from '~pages/pages-behind-login/position-list/position-list-page/position-list-filter-bar/position-list-filter-bar'
import PositionListTable from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table'
import {
  Divider,
  Wrapper,
} from '~pages/pages-behind-login/position-list/position-list.styles'
import { PositionVesselData } from '~pages/pages-behind-login/position-list/types/position-list-types'
import { TabNavigation } from '../exposure-page/exposure-page'
import { usePageViewTracking } from '../tracking/hooks/use-page-view-tracking'
import PositionListHeader from './position-list-header'

export default function PositionListPage() {
  const {
    data: positionListData,
    error: positionListDataError,
    isLoading,
  } = useApi<PositionVesselData[]>(
    ApiRoutes.POSITION_LIST.ALL,
    {},
    {
      // Refetch data every 15 minutes
      keepPreviousData: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
      refreshInterval: 1000 * 60 * 15,
      focusThrottleInterval: 1000 * 60 * 15,
    },
  )
  const {
    data: commercialPositionListData,
    error: commercialPositionListDataError,
    isLoading: isCommercialPositionListLoading,
  } = useApi<PositionVesselData[]>(
    ApiRoutes.POSITION_LIST.COMMERCIAL_MANAGEMENT,
    {},
    {
      // Refetch data every 15 minutes
      keepPreviousData: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
      refreshInterval: 1000 * 60 * 15,
      focusThrottleInterval: 1000 * 60 * 15,
    },
  )

  return (
    <PositionListViewProvider>
      <FilteredDataProvider
        initialData={positionListData as unknown as PositionVesselData[]}
        commercialManagementInitialData={
          commercialPositionListData as unknown as PositionVesselData[]
        }
      >
        <PositionList isLoading={isLoading} />
      </FilteredDataProvider>
    </PositionListViewProvider>
  )
}

interface Props {
  isLoading: boolean
}

function PositionList({ isLoading }: Props) {
  const {
    activeView: { filterSettings },
  } = usePositionListView()
  const { cargoGroup, pool } = filterSettings || {}
  const [showSaveNewListModal, setShowSaveNewListModal] = useState(false)
  const [showDeleteListModal, setShowDeleteListModal] = useState(false)
  const [showExportModal, setShowExportModal] = useState(false)
  const [listToBeDeleted, setListToBeDeleted] = useState('')
  const { setShowMyLists, showMyLists, myListsButtonRef } = useMyLists()
  usePageViewTracking()

  const isMTUser = useHasScope(ExactScopeName.maerskTankersUser)

  const handleShowSaveNewListModal = () => {
    setShowSaveNewListModal(true)
  }

  const handleShowDeleteListModal = (listName: string) => {
    setListToBeDeleted(listName)
    setShowDeleteListModal(true)
  }

  const handleToggleMyLists = () => {
    setShowMyLists((prev) => !prev)
  }

  if (isLoading) {
    return <LoadComponent />
  }

  return isMTUser ? (
    <>
      <PositionListHeader
        setShowMyLists={setShowMyLists}
        myListsButtonRef={myListsButtonRef}
      />
      <Wrapper>
        <PositionListFilterBar />
        <Divider />
        <TabNavigation
          extendedExposureUrl={`exposure?cargoGroup=${cargoGroup}&pool=${pool}`}
        />
        <PositionListTable />
      </Wrapper>
      <Popup
        open={showMyLists}
        anchor={myListsButtonRef.current}
        style={{ zIndex: 12 }}
      >
        <MyListsMenuContent
          handleShowSaveNewListModal={handleShowSaveNewListModal}
          handleShowDeleteListModal={handleShowDeleteListModal}
          handleToggleMyLists={handleToggleMyLists}
        />
      </Popup>
      <NewListModal
        setShowSaveNewListModal={setShowSaveNewListModal}
        showSaveNewListModal={showSaveNewListModal}
      />
      <DeleteListModal
        setShowDeleteListModal={setShowDeleteListModal}
        showDeleteListModal={showDeleteListModal}
        listToBeDeleted={listToBeDeleted}
      />
      <ExportModal
        setShowExportModal={setShowExportModal}
        showExportModal={showExportModal}
      />
      <ExportButtons setShowExportModal={setShowExportModal} />
    </>
  ) : (
    <Navigate to="/unauthorized" />
  )
}
