import { Button, TextButton } from '@maersktankersdigital/web-components'
import { Box } from '@mui/material'
import { useState } from 'react'
import { Modal } from '~components/molecules/modal/modal'
import { SharedFields } from './shared-fields'
import { SpotFields } from './spot-fields'
import { TcFields } from './tc-fields'

export interface FormInput {
  key: string
  value: string
}

const defaultFormInput = { type: 'SPOT' }

export function AddCargoModal({ onClose }: { onClose: () => void }) {
  const [formInput, setFormInput] = useState<{ [key: string]: string }>(
    defaultFormInput,
  )
  const [flow, setFlow] = useState<'SPOT' | 'T/C'>('SPOT')

  function updateFormInput({ key, value }: FormInput) {
    setFormInput({ ...formInput, [key]: value })
    if (key === 'type') {
      setFlow(value as 'SPOT' | 'T/C')
    }
  }

  return (
    <Modal open title="Create new cargo" onClose={onClose}>
      <>
        <SharedFields updateFormInput={updateFormInput} formInput={formInput} />
        {flow === 'SPOT' ? (
          <SpotFields updateFormInput={updateFormInput} formInput={formInput} />
        ) : (
          <TcFields updateFormInput={updateFormInput} formInput={formInput} />
        )}
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            onClick={() => {
              console.log(`TODO submit form data: ${formInput}`)
            }}
          >
            Save
          </Button>
          <TextButton onClick={onClose}>Cancel</TextButton>
        </Box>
      </>
    </Modal>
  )
}
