import { PageWrapper, TextButton } from '@maersktankersdigital/web-components'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { cargoListEnabled, getFeatureToggle } from '~utils/feature-toggles'
import { TabNavigation } from '../exposure-page/exposure-page'
import { AddCargoModal } from './componenets/add-cargo-modal'

export default function CargoListPage() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (!getFeatureToggle(cargoListEnabled)) {
      navigate('/position-list')
    }
  }, [navigate])

  return (
    <PageWrapper>
      <TabNavigation />
      <TextButton
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        Add Cargo
      </TextButton>
      {isModalOpen && <AddCargoModal onClose={() => setIsModalOpen(false)} />}
    </PageWrapper>
  )
}
