import { Select } from '@maersktankersdigital/web-components'
import { Box, TextField, Typography } from '@mui/material'
import { FieldsGroupProps } from './shared-fields'

export function TcFields({ updateFormInput, formInput }: FieldsGroupProps) {
  return (
    <>
      <Select label="pool *" name="pool" options={[]} />

      <Box>
        <Typography variant="label">Cargo *</Typography>
        <TextField />
      </Box>
    </>
  )
}
