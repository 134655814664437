import { TextButton } from '@maersktankersdigital/web-components'
import { Autocomplete, Box, Chip, TextField, Typography } from '@mui/material'
import { FormInput } from '../add-cargo-modal'
import { FieldsGroupProps } from '../shared-fields'

export function LoadPort({ updateFormInput, formInput }: FieldsGroupProps) {
  const sampleOptions = ['Port A', 'Port B', 'Port C', 'Port D', 'Port E']

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <AutocompleteCargoList
        label="Load Port"
        options={sampleOptions}
        updateFormInput={updateFormInput}
        input={formInput['load-port'] ? [...formInput['load-port']] : []}
      />
      <QuickSelectPort
        updateFormInput={updateFormInput}
        formInput={formInput}
      />
    </Box>
  )
}

function QuickSelectPort({ updateFormInput, formInput }: FieldsGroupProps) {
  const quickSelects = ['Port A', 'Port B']
  const allSelected = quickSelects.every((port) =>
    formInput['load-port']?.includes(port),
  )

  return (
    <Box sx={{ display: 'flex', gap: 2, mt: 2, alignItems: 'center' }}>
      {!allSelected && (
        <Typography variant="paragraph2">Quick select:</Typography>
      )}
      {quickSelects.map((port) => (
        <>
          {!formInput['load-port']?.includes(port) && (
            <TextButton
              key={port}
              onClick={() => {
                const current = formInput['load-port'] || []
                updateFormInput({
                  key: 'load-port',
                  // @ts-ignore
                  value: [...current, port],
                })
              }}
            >
              {port}
            </TextButton>
          )}
        </>
      ))}
    </Box>
  )
}

interface AutocompleteCargoListProps {
  label: string
  options: string[]
  updateFormInput: (arg: FormInput) => void
  input: string[]
}

export function AutocompleteCargoList({
  label,
  options,
  updateFormInput,
  input,
}: AutocompleteCargoListProps) {
  return (
    <>
      <Autocomplete
        value={input}
        sx={{ width: '344px' }}
        // freeSolo
        multiple
        autoSelect
        disablePortal
        disableClearable
        forcePopupIcon={false}
        options={options}
        renderTags={(value: readonly string[], getTagProps) => {
          return value.map((option: string, index: number) => {
            const { key, ...tagProps } = getTagProps({ index })
            return <Chip key={key} label={option} {...tagProps} />
          })
        }}
        onChange={(e, newValue) =>
          // @ts-ignore
          updateFormInput({ key: 'load-port', value: newValue })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ p: 0 }}
            label={<Typography variant="label">{label}</Typography>}
            placeholder="Search port..."
          />
        )}
      />
    </>
  )
}
