import { TextField } from '@mui/material'
import { useState } from 'react'
import { NumberFormatValues, PatternFormat } from 'react-number-format'

function validateTime(time: string) {
  const regex = /^([01]\d|2[0-3])-([0-5]\d)$/
  return regex.test(time)
}

export function TimeInputField({
  onChangeHandler,
  value,
}: {
  onChangeHandler: (value: string) => void
  value: string
}) {
  const [isValid, setIsValid] = useState<boolean>(true)

  function onValueChangeHandler(values: NumberFormatValues) {
    const isValid = validateTime(values.formattedValue)

    if (isValid) {
      onChangeHandler(values.formattedValue)
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }

  return (
    <PatternFormat
      format="##-##"
      customInput={TextField}
      sx={{ mt: 2 }}
      placeholder="mm-hh"
      onValueChange={onValueChangeHandler}
      value={value || ''}
      helperText={!isValid ? 'Invalid format use mm-hh' : ''}
    />
  )
}
