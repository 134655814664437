import { Select } from '@maersktankersdigital/web-components'
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import { FormInput } from './add-cargo-modal'
import { DateInputField } from './date-input-field'
import { TimeInputField } from './time-input-fileld'

export interface FieldsGroupProps {
  updateFormInput: (arg0: FormInput) => void
  formInput: { [key: string]: string }
}

export const rowStyle = {
  display: 'flex',
  rowGap: 5,
  columnGap: 7,
  flexWrap: 'wrap',
}

export function SharedFields({ updateFormInput, formInput }: FieldsGroupProps) {
  return (
    <>
      <Box sx={rowStyle}>
        <Box>
          <Typography variant="label">Type *</Typography>
          <RadioGroup
            onChange={(e, value) => updateFormInput({ key: 'type', value })}
            sx={{ flexDirection: 'row' }}
            value={formInput.type}
          >
            <FormControlLabel control={<Radio value="SPOT" />} label="SPOT" />
            <FormControlLabel control={<Radio value="T/C" />} label="T/C" />
          </RadioGroup>
        </Box>

        <Box>
          <Typography variant="label"> Private & confidential? *</Typography>
          <RadioGroup
            onChange={(e, value) => updateFormInput({ key: 'private', value })}
            sx={{ flexDirection: 'row' }}
          >
            <FormControlLabel control={<Radio value="Yes" />} label="Yes" />
            <FormControlLabel control={<Radio value="No" />} label="No" />
          </RadioGroup>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="label">Date quoted *</Typography>
          <DateInputField
            onChangeHandler={(value) =>
              updateFormInput({ key: 'date-quoted', value })
            }
            value={formInput['date-quoted']}
          />
        </Box>

        <Select label="person in charge (PIC) *" name="pic" options={[]} />
      </Box>

      <Box sx={{ ...rowStyle, mt: 5 }}>
        <Select label="status *" name="status" options={[]} />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="label">Subs due time</Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <DateInputField
              onChangeHandler={(value) =>
                updateFormInput({ key: 'subs-due-date', value })
              }
              value={formInput['subs-due-date']}
            />
            <TimeInputField
              onChangeHandler={(value) =>
                updateFormInput({ key: 'subs-due-time', value })
              }
              value={formInput['subs-due-time']}
            />
          </Box>
        </Box>
        <Box>
          <Typography variant="label">laycan *</Typography>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <DateInputField
              onChangeHandler={(value) =>
                updateFormInput({ key: 'laycan-date', value })
              }
              value={formInput['laycan-date']}
            />
            <Typography variant="label">+</Typography>
            <TextField
              sx={{ mt: 2 }}
              type="number"
              onChange={(e) =>
                updateFormInput({ key: 'laycan-offset', value: e.target.value })
              }
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}
