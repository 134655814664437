import * as React from 'react'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { IUserSettingsPositionListView } from '~api/user/read'
import { useAnimatedAlert } from '~components/molecules/animated-alert/animated-alert-provider'
import { useGetMe } from '~hooks/queries/me/use-get-me'
import { usePatchMeVT } from '~hooks/queries/me/use-patch-me-vt'
import {
  constantColumnsEnd,
  constantColumnsStart,
} from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table-controls/position-list-table-controls'
import {
  CargoGroupEnum,
  PositionVesselData,
  VesselSpecsEnum,
} from '~pages/pages-behind-login/position-list/types/position-list-types'
import { VesselPoolEnum } from '../types/exposure-types'
import { cargoGroupOptions } from '../position-list-page/position-list-filter-bar/filter-options'

type PositionListViewContextType = {
  activeView: IUserSettingsPositionListView
  clearFilters: () => void
  deleteView: (listName: string, listType: ListType) => Promise<void>
  isLoading: boolean
  persistActiveView: (listName?: string) => Promise<void>
  persistFavoriteVessel: (IMO: string) => void
  saveSettings: (settings: SettingsState) => void
  saveView: (
    nextView?: IUserSettingsPositionListView,
    activeViewName?: string,
  ) => void
  settings?: SettingsState
}

type SettingsState = {
  activeViewName?: string
  changesPending?: boolean
  exportedVessels?: string[]
  favoriteVessels?: string[]
  isExporting?: boolean
  myLists?: Record<string, IUserSettingsPositionListView | null>
  onlyFavorites?: boolean
}

export type FilterTypes = {
  cargoGroup?: CargoGroupEnum[]
  pool?: VesselPoolEnum[]
  showDuplicates?: boolean
  vessel?: string | null
  vesselSpecs?: VesselSpecsEnum[]
  worldArea?: string[]
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}
export interface ColumnSort {
  column: keyof PositionVesselData
  order: SortOrder
}

export type ListType = 'myLists'

export const initialColumnOrder = [
  ...constantColumnsStart,
  'openDate',
  'openPort',
  'voyages',
  'comments',
  ...constantColumnsEnd,
]

export const initialColumnSort = {
  column: 'openDate' as keyof PositionVesselData,
  order: SortOrder.ASC,
}

const PositionListViewContext = createContext<
  PositionListViewContextType | undefined
>(undefined)

const defaultFilters = {
  worldArea: ['ALL'],
  pool: [VesselPoolEnum.ALL],
  cargoGroup: [CargoGroupEnum.ALL],
  vesselSpecs: [VesselSpecsEnum.ALL],
  vessel: null,
  showDuplicates: true,
}

const defaultView: IUserSettingsPositionListView = {
  name: 'default',
  columns: initialColumnOrder,
  sortColumn: initialColumnSort,
  showHeaders: false,
  filterSettings: {
    worldArea: ['ALL'],
    pool: [VesselPoolEnum.ALL],
    cargoGroup: [CargoGroupEnum.ALL],
    vesselSpecs: [VesselSpecsEnum.ALL],
    vessel: null,
    showDuplicates: true,
  },
}

const emptyView: IUserSettingsPositionListView = {
  name: 'empty',
  columns: initialColumnOrder,
  sortColumn: initialColumnSort,
  showHeaders: false,
  filterSettings: {
    worldArea: ['ALL'],
    pool: [],
    cargoGroup: [],
    vesselSpecs: [VesselSpecsEnum.ALL],
    vessel: null,
    showDuplicates: true,
  },
}

function PositionListViewProvider({ children }: React.PropsWithChildren) {
  const [searchParams, setSearchParams] = useSearchParams()
  const { setStatus } = useAnimatedAlert()
  const [settings, setSettings] = useState<SettingsState>({
    myLists: {
      default: defaultView,
    },
    activeViewName: 'default',
    exportedVessels: [],
    favoriteVessels: [],
    changesPending: false,
    onlyFavorites: false,
  })
  const [activeView, setActiveView] =
    useState<IUserSettingsPositionListView>(emptyView)
  const { data, isLoading } = useGetMe()
  const { mutateAsync: updateUserSettings } = usePatchMeVT()
  const userSettings = data?.settings

  // Get views saved on user settings
  useEffect(() => {
    if (!userSettings?.positionList) return
    setSettings((currentSettings) => ({
      ...currentSettings,
      ...userSettings?.positionList,
    }))
  }, [userSettings?.positionList])

  // set active view based on settings
  useEffect(() => {
    const cargoGroupFilters =
      searchParams
        ?.get('cargoGroup')
        ?.split(',')
        .map(
          (cg) =>
            cargoGroupOptions.find((option) => option.label === cg)?.value,
        )
        .filter((value) => value !== undefined) || []

    const poolFilters =
      (searchParams?.get('pool')?.split(',') as VesselPoolEnum[]) || []

    if (!settings.activeViewName) return
    const nextActiveView = settings.myLists?.[settings.activeViewName]

    if (cargoGroupFilters.length > 0 || poolFilters.length > 0) {
      if (settings.activeViewName !== 'default') setSearchParams('')
      setActiveView((currentView) => ({
        ...nextActiveView,
        filterSettings: {
          ...currentView.filterSettings,
          cargoGroup:
            cargoGroupFilters.length > 0
              ? cargoGroupFilters
              : [CargoGroupEnum.ALL],
          pool: poolFilters,
        },
      }))
    } else if (nextActiveView) {
      setActiveView(nextActiveView)
    }
  }, [settings.activeViewName])

  const clearFilters = async () => {
    await updateUserSettings({
      settings: {
        positionList: {
          activeViewName: 'default',
        },
      },
    })
    setActiveView(defaultView)
    saveSettings({ changesPending: false })
  }

  const saveSettings = (settings: SettingsState) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      ...settings,
    }))
  }

  const deleteView = async (listName: string) => {
    const isActiveView = listName === settings.activeViewName
    const nextActiveViewName = isActiveView
      ? 'default'
      : settings.activeViewName
    try {
      const response = await updateUserSettings({
        settings: {
          positionList: {
            myLists: { [listName]: null },
            activeViewName: nextActiveViewName,
          },
        },
      })
      if (response) {
        setSettings((currentSettings) => ({
          ...currentSettings,
          ...response.settings?.positionList,
          changesPending: false,
        }))
        if (isActiveView) {
          setActiveView(defaultView)
        }
        setStatus('Your list was successfully deleted', 5000, 'success')
      }
    } catch (error) {
      setStatus('Error deleting list', 5000, 'error')
      console.error('Error deleting list:', error)
    }
  }

  const persistFavoriteVessel = async (IMO: string) => {
    const nextFavorites = settings?.favoriteVessels || []
    const isFavorite = nextFavorites.includes(IMO)
    const updatedFavorites = isFavorite
      ? nextFavorites.filter((vessel) => vessel !== IMO)
      : [...nextFavorites, IMO]
    saveSettings({ favoriteVessels: updatedFavorites })
    try {
      await updateUserSettings({
        settings: {
          positionList: { favoriteVessels: updatedFavorites },
        },
      })
    } catch (error) {
      setStatus('Error saving favorite vessel', 5000, 'error')
      console.error('Error setting favorite vessel:', error)
    }
  }

  // save view to user settings
  const persistActiveView = async (listName?: string) => {
    const saveListName = listName || (settings.activeViewName as string)
    const nextLists = {
      ...settings.myLists,
      [saveListName]: { ...activeView, name: saveListName },
    }

    saveSettings({
      myLists: nextLists,
      activeViewName: saveListName,
    })

    try {
      const response = await updateUserSettings({
        settings: {
          positionList: { myLists: nextLists, activeViewName: saveListName },
        },
      })
      if (response) {
        if (settings.changesPending) {
          setStatus(
            'Your changes have been successfully saved',
            5000,
            'success',
          )
        } else {
          setStatus('You have successfully made a new list', 5000, 'success')
        }
      }
      saveSettings({
        changesPending: false,
      })
    } catch (error) {
      setStatus('Error saving new list', 5000, 'error')
      console.error('Error persisting active view:', error)
    }
  }

  const persistActiveViewName = async (activeViewName: string) => {
    try {
      await updateUserSettings({
        settings: {
          positionList: {
            activeViewName,
          },
        },
      })
    } catch (error) {
      console.error('Error persisting active view name:', error)
    }
  }

  const saveView = (
    nextView?: IUserSettingsPositionListView,
    activeViewName?: string,
  ) => {
    if (nextView) {
      if ('showHeaders' in nextView) {
        setActiveView((currentView) => ({
          ...currentView,
          showHeaders: nextView.showHeaders,
        }))
      }

      if (nextView.filterSettings) {
        const nextFilterSettings = { ...activeView.filterSettings }

        for (const [name, value] of Object.entries(nextView.filterSettings)) {
          const valueArray = Array.isArray(value)
            ? value.map((val) => val.toString())
            : []
          let newFilterValue
          if (typeof value === 'string' || typeof value === 'boolean') {
            newFilterValue = value
          } else if (value) {
            const allIndex = valueArray.indexOf('ALL')
            if (value.length === 0) {
              newFilterValue = ['ALL']
            } else if (allIndex > -1 && allIndex === value.length - 1) {
              newFilterValue = ['ALL']
            } else {
              newFilterValue = value.filter((val) => val !== 'ALL')
            }
          }
          ;(nextFilterSettings as { [key: string]: any })[name] = newFilterValue
        }

        setActiveView((currentView) => ({
          ...currentView,
          filterSettings: nextFilterSettings,
        }))
      }

      if (nextView.columns) {
        setActiveView((currentView) => ({
          ...currentView,
          columns: nextView.columns,
        }))
      }

      if (nextView.sortColumn) {
        setActiveView((currentView) => ({
          ...currentView,
          sortColumn: nextView.sortColumn,
        }))
      }

      setSettings((currentSettings) => {
        // If searching for a vessel changes pending shouldn't be set to true
        if (
          nextView.filterSettings &&
          !nextView.filterSettings.hasOwnProperty('vessel')
        ) {
          return {
            ...currentSettings,
            changesPending: true,
          }
        }
        return currentSettings
      })
    }

    // Active list name needs to be persisted to user settings
    if (activeViewName) {
      setSettings((currentSettings) => ({
        ...currentSettings,
        activeViewName,
      }))
      persistActiveViewName(activeViewName)
    }
  }

  const value = useMemo(
    () => ({
      activeView,
      saveSettings,
      clearFilters,
      deleteView,
      isLoading,
      persistActiveView,
      persistFavoriteVessel,
      saveView,
      settings,
    }),
    [userSettings, settings, activeView, isLoading],
  )

  return (
    <PositionListViewContext.Provider value={value}>
      {children}
    </PositionListViewContext.Provider>
  )
}

function usePositionListView() {
  const context = useContext(PositionListViewContext)
  if (context === undefined) {
    throw new Error(
      'useUserSettings must be used within a UserSettingsProvider',
    )
  }
  return context
}

export { PositionListViewProvider, usePositionListView }
